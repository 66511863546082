<template>
  <AuthLayout>
    <template #leftSide>
      <LeftSection class="overflow-hidden max-w-3/4 mx-auto" />
    </template>
    <template #rightSide>
      <div>
        <ApplicationLogo
          class="mb-8 lg:hidden"
          variant="white"
          link="https://www.hirelookalikes.com"
        />
        <div class="mb-8 text-center lg:text-start w-full">
          <h1 class="text-2xl md:text-3xl leading-8 lg:leading-10 font-bold text-secondary-50 mb-4">
            Start your 14 Day Free Trial
          </h1>
          <p class="text-secondary-300">No credit card needed, Instant access.</p>
        </div>

        <Message v-if="errorMessage" :message="errorMessage" type="error" class="mb-4" />

        <div class="m-auto w-full">
          <form @submit.prevent="register">
            <div class="grid gap-4 mb-2">
              <BasicInput
                :errors="formErrors?.full_name"
                :value="formData.full_name"
                label="Full Name"
                name="full_name"
                type="text"
                component-type="floatInput"
                @input-change="(value) => (formData.full_name = value)"
                required
              />

              <BasicInput
                :errors="formErrors?.email"
                :value="formData.email"
                label="Business Email"
                name="email"
                type="email"
                component-type="floatInput"
                @input-change="(value) => (formData.email = value)"
                required
              />

              <PasswordInput
                :errors="formErrors?.password"
                :value="formData.password"
                label="Password"
                name="password"
                component-type="floatInput"
                required
                min-length="8"
                @input-change="(value) => (formData.password = value)"
              />

              <div class="flex flex-col self-center pl-1">
                <div>
                  <Checkbox
                    class="!text-primary-300 bg-transparent border-2 !border-secondary-300 !outline-none !ring-transparent !ring-offset-transparent items-center me-1 self-center"
                    name="agreement"
                    :checked="agreementChecked"
                    @change="agreementChecked = !agreementChecked"
                    required
                  />
                  <label class="text-sm text-secondary-300" for="agreement">
                    I hereby agree to the
                    <span
                      class="cursor-pointer text-primary-300 inter-medium underline hover:no-underline"
                      @click="showTermsAndConditions = true"
                    >
                      SaaS agreement and order
                    </span>
                  </label>
                </div>
                <InputError
                  v-if="!agreementChecked && attemptRegister"
                  class="!text-lg"
                  :messages="['This field is required']"
                />
              </div>
            </div>

            <div class="grid gap-y-4 mt-4">
              <Button type="submit" class="w-full text-center h-11" :loading="loading">
                Get Started for Free
              </Button>
            </div>

            <div class="grid gap-4 mt-4">
              <Separator text="or" text-class="text-secondary-300 text-sm" class="h-6" />

              <Button
                type="button"
                variant="outline-secondary"
                class="w-full"
                @click="registerWithGoogle"
              >
                <div class="flex justify-center text-center">
                  <Icon name="auth-google" class="w-5 h-5 me-2" />
                  Sign up with Google
                </div>
              </Button>

              <!-- <Button type="button" variant="outline-secondary" class="w-full">
              <div class="flex justify-center text-center">
                <Icon name="auth-apple" class="w-5 h-5 me-2" />
                Sign up with Apple
              </div>
            </Button> -->

              <div class="grid grid-cols-3 gap-x-2 mx-auto text-secondary-50">
                <div class="flex">
                  <Icon name="auth-curly-check" class="me-1 size-5 text-primary-300" />
                  ️GDPR
                </div>
                <div class="flex">
                  <Icon name="auth-curly-check" class="me-1 size-5 text-primary-300" />
                  ️CCPA
                </div>
                <div class="flex">
                  <Icon name="auth-curly-check" class="me-1 size-5 text-primary-300" />
                  ️SSL
                </div>
              </div>
            </div>
          </form>

          <p class="mt-10 text-center text-sm text-secondary-300">
            Already have an account?
            <Link
              variant="clear"
              link-type="link"
              to="/login"
              class="font-medium font-third text-primary-300"
              size="sm"
            >
              Sign In
            </Link>
          </p>
        </div>
        <TermsOfService v-if="showTermsAndConditions" @close="showTermsAndConditions = false" />
      </div>
    </template>
  </AuthLayout>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import { update } from '@intercom/messenger-js-sdk'
import { useUserStore } from '@/stores/user'
import AuthLayout from '@/components/AuthLayout.vue'
import TermsOfService from '@/components/TermsOfService.vue'
import PasswordInput from '@/components/Inputs/PasswordInput/PasswordInput.vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import Link from '@/components/Link/Link.vue'
import Icon from '@/components/Icon/Icon.vue'
import Message from '@/components/Message/Message.vue'
import Separator from '@/components/Separator/Separator.vue'
import Button from '@/components/Buttons/Button.vue'
import LeftSection from './Components/LeftSection.vue'
import ApplicationLogo from '@/components/ApplicationLogo.vue'
import Checkbox from '@/components/Inputs/Checkbox.vue'
import InputError from '@/components/Inputs/InputError.vue'

// import { useGtm } from '@gtm-support/vue-gtm'

const router = useRouter()
const userStore = useUserStore()
// const gtm = useGtm()

const formData = reactive({
  email: '',
  password: '',
  full_name: ''
})

const showTermsAndConditions = ref(false)
const errorMessage = ref('')
const loading = ref(false)
const userCreated = ref(false)
const formErrors = ref<null | Record<string, string[]>>(null)
const agreementChecked = ref(false)
const attemptRegister = ref(false)

// gtm.trackEvent({
//   event: 'user_status',
//   category: 'User',
//   action: 'Guest Visit',
//   label: 'Registration Page',
//   value: 1
// })

onMounted(() => {
  update({ hide_default_launcher: true })
})

const register = async (event: Event) => {
  loading.value = true

  await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/sanctum/csrf-cookie`)

  try {
    const res = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/register`, formData)

    const user = res.data
    userStore.login(user)

    formErrors.value = null

    userCreated.value = true
  } catch (error) {
    if (error.response.status === 422) {
      formErrors.value = error.response.data.errors
    } else {
      captureException(error)
      errorMessage.value = 'Oops,something went wrong. please contact support'
    }
  } finally {
    if (userCreated.value) {
      setTimeout(() => {
        redirectToPlatform()
      }, 500)
    }
    loading.value = false
  }
}

const redirectToPlatform = () => {
  const redirect = router.currentRoute.value.query.redirect || '/'
  router.push(redirect)
}

const registerWithGoogle = async () => {
  attemptRegister.value = true

  if (!agreementChecked.value) {
    return
  }

  await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/sanctum/csrf-cookie`)

  window.location.href = `${import.meta.env.VITE_LOOKALIKES_API}/auth/google`
}
</script>
