<template>
  <SectionBlock title="Candidate Location(s)">
    <div class="flex flex-col">
      <div class="flex flex-col gap-2 mb-4 p-2">
        <span class="text-secondary-900 font-medium text-sm">Within radius (miles):</span>
        <div class="bg-white rounded-lg w-[260px] border border-gray-200 p-2">
          <LocationRadius :value="locationRadius" @change="locationRadiusChange" />
        </div>
      </div>
      <LocationInput
        placeholder="Type and select"
        :api-url="locationAutoCompleteApiUrl"
        :delay="500"
        :values="locations"
        param-name="location"
        required
        :hide-option-if-search-empty="true"
        label="Enter a country, region/state or city:"
        @select="locationChange"
        @clear="locationChange"
      />
    </div>
  </SectionBlock>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { useSearchStore } from '@/stores/search'
import { useRoute } from 'vue-router'

import SectionBlock from '@/components/Tabs/SectionBlock.vue'
import LocationRadius from '@/components/LocationRadius/LocationRadius.vue'
import LocationInput from '@/components/Inputs/LocationInput.vue'

import axios from 'axios'

import { getLocationRecentSearches } from '@/utils/recent-searches'

interface IProps {
  selectedCountries: string[] | null
  selectedStates: string[] | null
  selectedCities: string[] | null
}

const props = defineProps<IProps>()
const emit = defineEmits(['locationChange', 'locationRadiusChange'])

const route = useRoute()
const searchStore = useSearchStore()
const locationAutoCompleteApiUrl = `${import.meta.env.VITE_LOOKALIKES_API}/api/location-auto-complete`
// const locationRadiusDisabled = ref(true)
const locationRadius = ref<number>(
  route.query.prefill && searchStore.searchPayload?.location_radius
    ? searchStore.searchPayload?.location_radius
    : 25
)
const locations = ref<string[]>(
  route.query.prefill && searchStore.searchPayload?.locations
    ? searchStore.searchPayload?.locations
    : []
)

const locationChange = (location: []) => {
  // if (location.length > 0 && location.some((loc) => loc.value.city !== '')) {
  //   locationRadiusDisabled.value = false
  // } else {
  //   locationRadiusDisabled.value = true
  // }
  emit('locationChange', location)
}

const locationRadiusChange = (value: number) => {
  emit('locationRadiusChange', value)
}

onMounted(() => {
  if (route.query.prefill) {
    emit('locationChange', locations.value)
  }
})

// onMounted(async () => {
//   if (selectedCountries.value && selectedCountries.value.length > 0) {
//     emit('countryChange', selectedCountries.value)
//     await loadStates()
//   }
//   if (selectedStates.value && selectedStates.value.length > 0) {
//     emit('stateChange', selectedStates.value)
//     await loadCities()
//   }
//   window.addEventListener('changed-recently-searches', () => {
//     recentlySearchedCountries.value = getLocationRecentSearches('countries', 3)
//     recentlySearchedStates.value = getLocationRecentSearches(
//       'states',
//       3,
//       searchStore.searchLocation.states
//     )
//     recentlySearchedCities.value = getLocationRecentSearches(
//       'cities',
//       3,
//       searchStore.searchLocation.cities
//     )
//   })
// })
</script>
