<template>
  <div class="bg-secondary-50 rounded-lg">
    <div class="flex flex-col md:flex-row gap-6 md:flex-nowrap">
      <div class="md:basis-full">
        <SelectInput
          class="w-full"
          label="Past role title:"
          label-class="text-secondary-900 mb-3 leading-5 !font-medium"
          input-class="h-10 border-secondary-200"
          dropdown-class="border-secondary-200"
          :api-url="jobTitleAutocompleteApi"
          :name="`pastRoleTitle-${index}`"
          placeholder="Type and select"
          :delay="500"
          mode="single"
          value-key="canonical_title"
          label-key="title"
          clearable
          param-name="title"
          save-values-as-objects
          clear-on-select
          :selected-value="{
            label: pastRole.title,
            value: pastRole.canonical_title
          }"
          close-on-select
          :hide-option-if-search-empty="true"
          :clear-on-blur="true"
          @change="pastRoleTitleChange"
        />
      </div>
      <div class="flex">
        <div class="w-full md:w-40 lg:w-56">
          <BasicInput
            :name="`pastRoleMinYears-${index}`"
            :value="pastRole.minYears ? simplifiedYearConversion(pastRole.minYears) : ''"
            label-class="text-secondary-900 leading-5 !font-medium"
            input-class="h-10"
            label="Min Years in Past Role"
            clearable
            @inputChange="
              (value) => {
                emit('pastRoleMinYearsChange', index, value)
              }
            "
          />
        </div>
        <div class="mt-8 ms-6" v-if="showRemoveButton">
          <div
            class="size-10 text-secondary-600 border rounded-lg border-current content-center"
            @click="$emit('remove-past-role', props.index)"
          >
            <Icon name="svg-trash" class="size-6 m-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits } from 'vue'
import SelectInput from '@/components/Inputs/Select/SelectInput.vue'
import BasicInput from '@/components/Inputs/BasicInput/BasicInput.vue'
import Icon from '@/components/Icon/Icon.vue'
import { simplifiedYearConversion } from '@/utils/date'

import type { PastRole } from '@/stores/types'

interface IProps {
  pastRole: PastRole
  index: number
  showRemoveButton: boolean
}

const props = defineProps<IProps>()

const emit = defineEmits(['pastRoleTitleChange', 'pastRoleMinYearsChange', 'remove-past-role'])

const jobTitleAutocompleteApi = `${import.meta.env.VITE_LOOKALIKES_API}/api/position-title-auto-complete`

const pastRoleTitleChange = (value: { label: string; value: string } | null) => {
  if (value) {
    emit('pastRoleTitleChange', props.index, value.label, value.value)
  } else {
    emit('pastRoleTitleChange', props.index, null, null)
  }
}
</script>
