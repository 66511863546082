import { createRouter, createWebHistory } from 'vue-router'

import axios from 'axios'

import NewRegisterPage from '@/views/RegisterPage/RegisterPage.vue'
import NewLoginPage from '@/views/LoginPage/LoginPage.vue'
import ForgotPasswordPage from '@/views/PasswordPage/ForgotPasswordPage.vue'
import ResetPasswordPage from '@/views/PasswordPage/ResetPasswordPage.vue'
// import LookAlikeResults from '@/views/LookAlikeResult/LookAlikeResults.vue'
import CandidatePage from '@/views/CandidatePage/CandidatePage.vue'
import NotFound from '@/views/Errors/NotFound.vue'
import SearchResults from '@/views/Results/SearchResults.vue'

import { useUserStore } from '@/stores/user'
import SearchPage from '@/views/Search/SearchPage.vue'
import Invite from '@/views/User/Invite.vue'
import MyAccount from '@/views/User/MyAccount.vue'
import AccountDetails from '@/views/User/AccountDetails.vue'
import Security from '@/views/User/Security.vue'
import Billing from '@/views/User/Billing/Billing.vue'
import Team from '@/views/User/Team.vue'
import API from '@/views/User/API.vue'
import ProjectsPage from '@/views/Projects/ProjectPage/ProjectsPage.vue'
import SavedSearchesPage from '@/views/Searches/SavedSearchesPage.vue'
import RecentSearchesPage from '@/views/Searches/RecentSearchesPage.vue'
import ProjectCandidatesPage from '@/views/Projects/ProjectsCandidatesPage/ProjectCandidatesPage.vue'

let intervalId: NodeJS.Timeout | null = null

function addLoggedInGoogleTagManager() {
  if (import.meta.env.MODE !== 'production') return
  // Create the GTM script element
  const gtmScript = document.createElement('script')
  gtmScript.id = 'gtm-script-logged-in'
  gtmScript.innerHTML = `
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.id = 'gtm-script-logged-in'
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', 'GTM-PCKG52X6')
  `
  document.head.appendChild(gtmScript)

  // Create the GTM noscript element
  const gtmNoScript = document.createElement('noscript')
  gtmNoScript.id = 'gtm-script-logged-in-noscript'
  gtmNoScript.innerHTML = `
     <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-PCKG52X6"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
  `
  document.body.insertAdjacentElement('afterbegin', gtmNoScript)
}

function addLoggedOutGoogleTagManager() {
  if (import.meta.env.MODE !== 'production') return
  // Create the GTM script element
  const gtmScript = document.createElement('script')
  gtmScript.id = 'gtm-script-logged-out'
  gtmScript.innerHTML = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.id='gtm-script-logged-out';j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MZ5HS7B6');
  `
  document.head.appendChild(gtmScript)

  // Create the GTM noscript element
  const gtmNoScript = document.createElement('noscript')
  gtmNoScript.id = 'gtm-script-logged-out-noscript'
  gtmNoScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MZ5HS7B6"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `
  document.body.insertAdjacentElement('afterbegin', gtmNoScript)
}

function removeGoogleTagManager(id: string) {
  if (import.meta.env.MODE !== 'production') return

  // Remove the GTM script element
  const gtmScript = document.getElementById(id)
  if (gtmScript) {
    gtmScript.parentNode.removeChild(gtmScript)
  }

  // Remove the GTM noscript element
  const gtmNoScript = document.getElementById(id + '-noscript')
  if (gtmNoScript) {
    gtmNoScript.parentNode.removeChild(gtmNoScript)
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/register',
      name: 'register',
      component: NewRegisterPage,
      beforeEnter: async (to, from) => {
        removeGoogleTagManager('gtm-script-logged-in')
        addLoggedOutGoogleTagManager()
        try {
          const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/user`)

          useUserStore().login(res.data)

          return { name: 'homePage' }
        } catch (error) {
          return true
        }
      }
    },
    {
      path: '/login',
      name: 'login',
      component: NewLoginPage,
      beforeEnter: async (to, from) => {
        removeGoogleTagManager('gtm-script-logged-in')
        addLoggedOutGoogleTagManager()
        try {
          const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/user`)

          useUserStore().login(res.data)

          return to.query.redirect ?? { name: 'homePage' }
        } catch (error) {
          return true
        }
      }
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: ForgotPasswordPage,

      beforeEnter: async (to, from) => {
        removeGoogleTagManager('gtm-script-logged-in')
        addLoggedOutGoogleTagManager()
        try {
          const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/user`)

          useUserStore().login(res.data)

          return { name: 'homePage' }
        } catch (error) {
          return true
        }
      }
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPasswordPage,

      beforeEnter: async (to, from) => {
        removeGoogleTagManager('gtm-script-logged-in')
        addLoggedOutGoogleTagManager()
        try {
          const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/user`)

          useUserStore().login(res.data)

          return { name: 'homePage' }
        } catch (error) {}
        return true
      }
    },
    {
      path: '/',
      name: 'homePage',
      component: SearchPage,
      meta: { layout: 'default' },
      beforeEnter: (to, from) => {
        removeGoogleTagManager('gtm-script-logged-out')
        addLoggedInGoogleTagManager()
        return true
      }
    },

    {
      path: '/search-results/:searchId',
      name: 'searchResults',
      component: SearchResults,
      meta: { layout: 'default', notDefaultPadding: true }
    },
    {
      path: '/searches/saved',
      name: 'savedSearches',
      component: SavedSearchesPage,
      meta: { layout: 'default', notDefaultPadding: true }
    },
    {
      path: '/searches/recent',
      name: 'recentSearches',
      component: RecentSearchesPage,
      meta: { layout: 'default', notDefaultPadding: true }
    },
    {
      path: '/search/:searchId/candidate/:searchResultId',
      name: 'candidate',
      component: CandidatePage,
      meta: { layout: 'default' }
    },
    {
      path: '/project/:projectId/candidate/:searchResultId',
      name: 'projectCandidate',
      component: CandidatePage,
      meta: { layout: 'default' }
    },
    {
      path: '/projects',
      name: 'projects',
      component: ProjectsPage,
      meta: { layout: 'default', notDefaultPadding: true }
    },
    {
      path: '/project/:projectId',
      name: 'projectCandidates',
      component: ProjectCandidatesPage,
      meta: { layout: 'default', notDefaultPadding: true }
    },
    {
      path: '/404',
      name: 'notFound',
      component: NotFound,
      meta: { layout: 'default' }
    },
    {
      path: '/profile',
      name: 'profile',
      component: MyAccount,
      meta: { layout: 'default' }
    },
    {
      path: '/profile/account-details',
      name: 'accountDetails',
      component: AccountDetails,
      meta: { layout: 'default' }
    },
    {
      path: '/profile/security',
      name: 'security',
      component: Security,
      meta: { layout: 'default' }
    },
    {
      path: '/profile/billing',
      name: 'billing',
      component: Billing,
      meta: { layout: 'default' }
    },
    {
      path: '/profile/team',
      name: 'team',
      component: Team,
      meta: { layout: 'default' }
    },
    {
      path: '/profile/api',
      name: 'api',
      component: API,
      meta: { layout: 'default' }
    },
    {
      path: '/invite',
      name: 'invite',
      component: Invite,
      meta: { layout: 'default' }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: NotFound,
      meta: { layout: 'default' }
    }
  ]
})

router.beforeEach(async (to, from) => {
  if (
    to.name !== 'login' &&
    to.name !== 'register' &&
    to.name !== 'forgotPassword' &&
    to.name !== 'resetPassword'
  ) {
    if (!intervalId) {
      intervalId = setInterval(
        () => {
          axios
            .get(`${import.meta.env.VITE_LOOKALIKES_API}/api/keep-alive`)
            .then()
            .catch((err) => {
              console.error('Failed to refresh session')
              console.error(err)
              window.location.href = '/login'
            })
        },
        60 * 60 * 1000 // Every 1 hour
      )
    }
  } else {
    if (intervalId) {
      clearInterval(intervalId)
      intervalId = null
    }
  }

  if (
    // make sure the user is authenticated
    !useUserStore().isLoggedIn &&
    // ❗️ Avoid an infinite redirect
    to.name !== 'login' &&
    to.name !== 'register' &&
    to.name !== 'forgotPassword' &&
    to.name !== 'resetPassword'
  ) {
    // redirect the user to the login page
    return { name: 'login', query: { redirect: to.fullPath } }
  }

  return true
})

export default router
